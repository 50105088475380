import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
	selector: 'core-base-control',
	template: 'no-ui',
})
export class BaseControlComponent implements ControlValueAccessor, OnInit {
	/** Whether the parent form is submitted */
	@Input() isSubmitted = false;
	/** Whether to apply 'valid' styling */
	@Input() showValid?: boolean = false;
	/** Whether to apply 'invalid' styling */
	@Input() showInvalid?: boolean = false;
	/** Identifier of the input for label binding */
	@Input() identifier?: string;

	/** Control value */
	value = '';
	/** NgControl reference */
	control?: NgControl;

	constructor(@Inject(Injector) public injector: Injector) {}

	ngOnInit(): void {
		this.control = this.injector.get(NgControl);

		this.control?.control?.valueChanges.subscribe((value) => {
			this.writeValue(value);
		});
	}

	/** Update the control value */
	updateValue(value: string) {
		if (this.control?.disabled) {
			return;
		}
		this.onChange(value);
		this.control?.control?.patchValue(value);
		this.onTouched();
	}

	// istanbul ignore next
	// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
	private onChange = (_: any): void => undefined;
	// istanbul ignore next
	private onTouched = (): void => undefined;

	/** Handle value set by form */
	writeValue(value: string): void {
		this.value = value || '';
	}

	/** Registers function to call on control change */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	registerOnChange(callback: (_: any) => unknown): void {
		this.onChange = callback;
	}

	/** Registers function to call on control touch */
	registerOnTouched(callback: () => unknown): void {
		this.onTouched = callback;
	}
}
